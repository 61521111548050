import * as React from 'react';

import { theme } from '../../../../styles/2020/theme';

import { P, Section, Text } from '../../common/Primitives';
import { Clamp } from '../../layout/Clamp';
import { Column } from '../../layout/Column';
import { Grid } from '../../layout/Grid';
import { Icon } from '../../layout/Icon';
import { Sidebar } from '../../layout/Sidebar';

type FeatureListBlockProps = {
  title: string;
  image: React.ReactNode;
  features: { title: string; text: string }[];
};

export function FeatureListBlock({ title, image, features }: FeatureListBlockProps) {
  return (
    <Section>
      <Column space={theme.space.m}>
        <Text as="h3" size="xl" color="brand" bold>
          {title}
        </Text>

        <Sidebar
          contentMin="60%"
          sideWidth="180px"
          justify="flex-start"
          align="flex-start"
          space={theme.space.l}
        >
          <Clamp max="250px">{image}</Clamp>

          <Grid min="300px" space={theme.space.m}>
            {features.map((feature) => (
              <Column key={feature.title} space={theme.space.xxxs}>
                <Text as="h4" size="s" bold>
                  <Icon iconId="check" iconColor="green2" text={feature.title} space="6px" />
                </Text>

                <P size="s">{feature.text}</P>
              </Column>
            ))}
          </Grid>
        </Sidebar>
      </Column>
    </Section>
  );
}
