import * as React from 'react';

import type { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { FeatureListBlock } from 'stile-shared/src/components/2020/blocks/FeatureListBlock';
import { HeroBlock } from 'stile-shared/src/components/2020/blocks/HeroBlock';
import { TeaserBlock } from 'stile-shared/src/components/2020/blocks/TeaserBlock';
import { TextBlock } from 'stile-shared/src/components/2020/blocks/TextBlock';
import { Link } from 'stile-shared/src/components/2020/common/Link';
import { P } from 'stile-shared/src/components/2020/common/Primitives';
import { Quote } from 'stile-shared/src/components/2020/common/Quote';
import { Center } from 'stile-shared/src/components/2020/layout/Center';

import { getPageData } from 'utils/getPageData';

import { PageLayout } from 'templates/2020/PageLayout';

import { CtaBlock } from 'components/blocks/CtaBlock';
import { NextPageBlock } from 'components/blocks/NextPageBlock';

export { Head } from 'templates/2020/Head';

function TechnologyForTeachingPage(props: PageProps) {
  const { hero } = getPageData(props.location.pathname);

  return (
    <PageLayout {...props}>
      <HeroBlock
        {...hero}
        image={
          <StaticImage
            loading="eager"
            aspectRatio={2.37}
            src="../../../../stile-shared/assets/images/technology-for-teaching/cover_us_technology_for_teaching.png"
            alt="A teacher pointing on a wall projection of the Stile app"
          />
        }
      />

      <TextBlock>
        <P>
          Stile’s resources are presented via our award-winning online teaching and learning
          platform, purpose-built to support both direct instruction and self-paced learning in
          modern science classrooms.
        </P>

        <P>
          Envisioned as a teacher’s ultimate sidekick, Stile’s technology is designed to put
          teachers in the driver’s seat. It encourages collaboration and class debates, while making
          it simple to differentiate instruction and provide timely feedback.
        </P>

        <P>
          Stile’s technology was co-created with teachers in US and Australian classrooms. It’s been{' '}
          <Link url="/why-choose-stile/what-teachers-are-saying/">battle-tested</Link> by hundreds
          of thousands of students, and we’re constantly refining it.
        </P>
      </TextBlock>

      <TextBlock title="The ultimate sidekick">
        <P>
          Stile fits perfectly into your existing teaching workflow: <strong>preparing</strong> for
          class, <strong>teaching</strong> your class and <strong>analyzing</strong> student
          performance.
        </P>
      </TextBlock>

      <Center max="1100px">
        <TeaserBlock
          asideWidth="700px"
          title="Prepare"
          image={
            <StaticImage
              src="../../../../stile-shared/assets/images/technology-for-teaching/pta_prepare.png"
              alt="An illustration of a teacher planning lessons. They look relaxed and there are screenshots of units from the Stile Library behind them"
            />
          }
        >
          <P>
            Teachers can choose from a library of pre-made interactive science lessons, prepare for
            class by reviewing detailed teaching notes and customize any aspect of those lessons
            with point-and-click tools. Schools can organize their customized science programs,
            share them, and reuse them year after year.
          </P>
        </TeaserBlock>

        <TeaserBlock
          asideWidth="700px"
          contentSide="right"
          title="Teach"
          image={
            <StaticImage
              src="../../../../stile-shared/assets/images/technology-for-teaching/pta_teach.png"
              alt="An illustration of a teacher presenting a chemical on a board, and students brainstorming elements within the chemical"
            />
          }
        >
          <P>
            Stile comes alive in the classroom. Teachers can present lessons on the projector while
            students participate live on their own devices. They can vote on polls, participate in
            class discussions, and contribute their answers to questions.
          </P>
          <P>
            Teachers have full control over which lessons are available to each student. All work
            completed by students is visible to the teacher in real time. By making learning
            visible, Stile makes it easier than ever to get a gauge of student understanding, right
            then and there in class.
          </P>
        </TeaserBlock>

        <TeaserBlock
          asideWidth="700px"
          title="Analyze"
          image={
            <StaticImage
              src="../../../../stile-shared/assets/images/technology-for-teaching/pta_analyse.png"
              alt="An illustration of a teacher marking student work, with an example graph of student responses from Stile in the background"
            />
          }
        >
          <P>
            We love the idea of data-informed teaching. Stile does a bunch of number crunching
            behind the scenes to give teachers insights into student learning. Armed with an
            understanding of student performance, teachers can quickly and easily provide feedback
            directly on student work. Students can revisit their work, and all revisions are saved
            as evidence of learning progression.
          </P>
        </TeaserBlock>
      </Center>

      <TextBlock variant="dark" title="No technology for technology’s sake">
        <P>
          We hate it when people use technology for the sake of it. For example, paper is amazing
          for scribbling notes, or writing out complex math equations. In fact, there are quite a
          number of resources we produce that don’t use technology.
        </P>

        <P>
          Wherever we use technology, we’ve carefully considered why, and only done so when we can
          see a clear benefit to students.
        </P>

        <Quote
          text="Any sufficiently advanced technology is indistinguishable from magic."
          credit="Arthur C. Clark"
        />
      </TextBlock>

      <FeatureListBlock
        title="Enable modern pedagogy"
        image={
          /* decorative image does not require alt text */
          <StaticImage
            src="../../../../stile-shared/assets/images/technology-for-teaching/spot_technology_1.png"
            alt=""
          />
        }
        features={[
          {
            title: 'Data-informed teaching',
            text: 'Enhance your teaching practice with the insights provided by data analytics.',
          },
          {
            title: 'Visible thinking',
            text: 'Gain insight into your students’ thought patterns by incorporating visible thinking routines into your lessons.',
          },
          {
            title: 'Blended learning',
            text: 'Easily combine online activities and offline tasks within your existing classroom workflow.',
          },
          {
            title: 'Flipped learning',
            text: 'Fast and easy video embedding designed with flipped learning in mind.',
          },
          {
            title: 'Differentiated instruction',
            text: 'Stile contains customization tools that allow you to tailor lesson content to different learning needs.',
          },
        ]}
      />

      <FeatureListBlock
        title="Build interactive lessons"
        image={
          /* decorative image does not require alt text */
          <StaticImage
            src="../../../../stile-shared/assets/images/technology-for-teaching/spot_technology_2.png"
            alt=""
          />
        }
        features={[
          {
            title: 'Live brainstorms',
            text: 'Allow students to collaborate in real time. The Live Brainstorm widget allows students to quickly contribute their ideas to a class discussion.',
          },
          {
            title: 'Drag-and-drop multimedia',
            text: 'Easily add as much of your own media into your lessons as you like, and in any format.',
          },
          {
            title: 'Live polls',
            text: 'Gauge student understanding, survey opinions, or facilitate peer-to-peer learning.',
          },
          {
            title: 'Save all work in one place',
            text: 'Any student work done offline or in other applications can be uploaded right in the lessons – presentations, videos, photos and more.',
          },
          {
            title: 'Incorporate existing materials',
            text: 'Don’t reinvent the wheel; include your existing lesson materials in Stile.',
          },
          {
            title: 'Adapt and reuse',
            text: 'Teachers can build a library of customized student resources that can be used year after year and shared with colleagues.',
          },
          {
            title: 'Real-time class discussion',
            text: 'Students can collaborate, share ideas and discuss work in a safe, moderated environment.',
          },
          {
            title: 'Open-ended tasks',
            text: 'Empower students to respond with a mixture of text, multimedia, graphs and sketches.',
          },
          {
            title: 'Paperless classroom',
            text: 'Save time, money and the environment by reducing printing and photocopying.',
          },
          {
            title: 'Annotation, sequencing and drawing questions',
            text: 'The flexible “interactive canvas” question type enables a whole host of innovative visual questions.',
          },
          {
            title: 'Mind mapping',
            text: 'Have students brainstorm what they already know about a new topic or reinforce the grouping of ideas in a mind map.',
          },
          {
            title: 'Interactive questions',
            text: 'Create interactive Cloze drag-and-drop questions to challenge students with different question types.',
          },
        ]}
      />

      <FeatureListBlock
        title="Classroom workflow"
        image={
          /* decorative image does not require alt text */
          <StaticImage
            src="../../../../stile-shared/assets/images/technology-for-teaching/spot_technology_3.png"
            alt=""
          />
        }
        features={[
          {
            title: 'Real-time feedback',
            text: 'Keep students on track with real-time written or audio feedback.',
          },
          {
            title: 'Everything in one place',
            text: 'Lesson content, student responses, class discussions and feedback, all together.',
          },
          {
            title: 'Due dates',
            text: 'It’s simple to set, change and check upcoming due work.',
          },
          {
            title: 'Fully worked model answers',
            text: 'Model answers are embedded into each open-ended question in Stile and teachers can modify these or create their own.',
          },
          {
            title: 'Real-time class insights',
            text: 'Live analytics and performance insights help identify student misconceptions earlier than ever.',
          },
          {
            title: 'Automated instant feedback',
            text: 'Pre-set responses give students even faster feedback.',
          },
        ]}
      />

      <FeatureListBlock
        title="Customized to your science classroom"
        image={
          /* decorative image does not require alt text */
          <StaticImage
            src="../../../../stile-shared/assets/images/technology-for-teaching/spot_technology_4.png"
            alt=""
          />
        }
        features={[
          {
            title: 'Pre-made assessments with RiskAssess',
            text: '130 Stile practical activities have a pre-made template in RiskAssess.',
          },
          {
            title: 'Flexible assessment tools',
            text: 'Diagnostic, formative and summative assessment tasks are all included, or you can design your own.',
          },
          {
            title: 'Digital workbook',
            text: 'Worksheet creation, organization and assessment is built into the lesson and seamlessly incorporated into the experience.',
          },
          {
            title: 'Quizzes',
            text: 'Quickly test your students’ understanding of a topic with pre-made quizzes or make your own.',
          },
          {
            title: 'Streamlined reporting',
            text: 'Keep parents informed when it matters most, with easy-to-use reporting tools.',
          },
          {
            title: 'Digital portfolio',
            text: 'Each student’s work, results and progress are saved and can be referred to year after year.',
          },
          {
            title: 'Digital lab book',
            text: 'Students record experiment results straight into Stile. Placing their data alongside the lesson content makes it easier to revise, and easier for teachers to assess learning and progress.',
          },
          {
            title: 'Comprehensive lab handbook',
            text: 'All of our experiments are complemented with a laboratory technician guide.',
          },
        ]}
      />

      <FeatureListBlock
        title="Interoperable"
        image={
          /* decorative image does not require alt text */
          <StaticImage
            src="../../../../stile-shared/assets/images/technology-for-teaching/spot_technology_5.png"
            alt=""
          />
        }
        features={[
          {
            title: 'Any device, anywhere',
            text: 'Seamless switching between a tablet at school and a laptop at home, for students and teachers.',
          },
          {
            title: 'iOS app',
            text: 'Students can bring work from any iPad app into Stile for feedback and marking.',
          },
          {
            title: 'Integration API',
            text: 'Incorporates seamlessly into your school’s software ecosystem.',
          },
          {
            title: 'Canvas integration',
            text: 'Easily sync marks from Stile to Canvas.',
          },
          {
            title: 'Any file format',
            text: 'Just upload whatever you have and Stile takes care of the rest.',
          },
        ]}
      />

      <NextPageBlock path="/why-choose-stile/benefits/" />

      <CtaBlock />
    </PageLayout>
  );
}

export default TechnologyForTeachingPage;
